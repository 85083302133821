.banner {
  width: 100%;
  height: 40vw;
  background-color: #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.banner .banner-section {
  width: 50%;
  padding-left: 10%;
}
.banner .banner-section--title {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  line-height: 1.6;
  color: black;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 4vw;
}
.banner .banner-section--title .banner-section--title__experience {
  color: #ee9f9f;
}
.banner .banner-section--title .banner-section--title__services {
  display: block;
}
.banner .banner-section--description {
  font-family: 'PT Sans', serif;
  line-height: 1.6;
  color: #9c9c9c;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: rfs(1.2rem);
}
.banner .banner-section--button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: rfs(0.8rem);
  color: black;
  border-color: #ffd0d6;
  background-color: transparent;
  border-width: 3px;
  border-radius: 20px;
  padding: 15px;
}
.banner .banner-section--button:active:hover {
  background-color: #ee9f9f;
  border-color: #ee9f9f;
  color: white;
}
.banner .banner-section--button:focus {
  outline: 0;
  box-shadow: none !important;
}
.banner .banner-image {
  height: 100%;
  float: right;
}
.banner .fade-enter {
  opacity: 0.01;
}
.banner .fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.banner .fade-leave {
  opacity: 1;
}
.banner .fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
@media (max-width: 1099px) {
  .banner-section {
    width: 60%;
  }
  .banner-section--title {
    font-size: rfs(1rem);
  }
  .banner-section--description {
    display: none;
  }
}
@media (max-width: 425px) {
  .banner {
    height: 55vw;
  }
  .banner .banner-section {
    width: 100%;
    padding-left: 0%;
    margin: 0 auto;
    text-align: center;
  }
  .banner .banner-section--title {
    font-size: rfs(2rem);
  }
  .banner .banner-image {
    display: none;
  }
}
