html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.main {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 150px;
}
