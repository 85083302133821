.contact {
  padding-top: 3%;
  padding-bottom: 100px;
  background-color: #f6f6f6;
}
.contact .contact-section {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.contact .contact-section .contact-section--title {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  line-height: 1.6;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  color: black;
  font-size: rfs(3rem);
  padding-bottom: 20px;
}
.contact .contact-section .contact-section--title:after {
  content: ' ';
  display: block;
  border: 2px solid #ee9f9f;
  width: 10%;
  margin: 0 auto;
}
.contact .contact-section .contact-section--subtitle {
  font-family: 'PT Sans', serif;
  color: #9c9c9c;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: rfs(1.2rem);
}
.contact .contact-form {
  width: 75%;
  margin: 0 auto;
}
.contact .contact-form .contact-col {
  padding: 5px;
}
.contact .contact-form .contact-col .contact-col--control {
  border-radius: 100px !important;
}
.contact .contact-form .contact-col .comments {
  margin-bottom: 20px;
}
.contact .contact-form .contact-button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: rfs(1rem);
  color: black;
  border-color: #ffd0d6;
  background-color: transparent;
  border-width: 2px;
  border-radius: 20px;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  margin-bottom: 15px;
}
.contact .contact-form .contact-button:active:hover {
  background-color: #ee9f9f;
  border-color: #ee9f9f;
  color: white;
}
.contact .contact-form .contact-button:focus {
  outline: 0;
  box-shadow: none !important;
}
.contact .contact-form .clicked {
  animation: mymove 2s normal forwards;
  animation-iteration-count: 1;
}
@keyframes mymove {
  from {
    background-color: #f5e2e2;
    border-color: #ee9f9f;
  }
  to {
    background-color: #39C16C;
    border-color: #39C16C;
  }
}
