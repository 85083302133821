.about {
  text-align: center;
  background-color: #f8f5f5;
}
.about .about-row {
  margin: 0% 5% 5% 5%;
  padding-top: 3%;
}
.about .about-row .about-col-collage {
  background-image: url('../images/collage.png');
  display: table-cell;
  background-position: center;
  background-size: cover;
  height: 50vw;
}
.about .about-row .about-col-text {
  display: flex;
  align-items: center;
}
.about .about-row .about-col-text .about-section {
  width: 80%;
  text-align: left;
  margin-left: auto;
  margin-right: 0;
}
.about .about-row .about-col-text .about-section .about-section--title {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  line-height: 1.6;
  padding-bottom: 15px;
  color: black;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: rfs(3rem);
}
.about .about-row .about-col-text .about-section .about-section--title .about-section--title--secondary {
  display: block;
}
.about .about-row .about-col-text .about-section .about-section--subtitle {
  font-family: 'PT Sans', serif;
  line-height: 1.6;
  color: black;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: rfs(1.2rem);
}
.about .about-row .about-col-text .about-section .about-section--description {
  font-family: 'PT Sans', serif;
  line-height: 1.6;
  color: #9c9c9c;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: rfs(1.2rem);
}
@media (max-width: 992px) {
  .about {
    padding-bottom: 2vh;
  }
  .about .about-row .about-col-collage {
    margin-left: 20px;
    height: 60vw;
  }
  .about .about-row .about-col-text .about-section {
    text-align: center;
    margin: 0 auto;
  }
}
