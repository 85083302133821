.services {
  padding-top: 3%;
  padding-bottom: 5%;

  .services-section {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 5vh;

    .services-section--title {
      font-family: 'Playfair Display', serif;
      font-weight: 700;
      line-height: 1.6;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -webkit-text-size-adjust: 100%;
      color: black;
      font-size: rfs(3rem);
      padding-bottom: 20px
    }

    .services-section--title:after {
      content:' ';
      display:block;
      border:2px solid #ee9f9f;
      width: 10%;
      margin: 0 auto;
    }

    .services-section--subtitle {
      font-family: 'PT Sans', serif;
      color: #9c9c9c;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -webkit-text-size-adjust: 100%;
      font-size: rfs(1.2rem);
    }
  }

  .services-collection {
    .services-row {
      padding-left: 10%;
      padding-right: 10%;

      .services-col {
        padding: 20px;
        text-align: center;

        .services-col--image {
          width: 8vh;
          padding-bottom: 20px;
        }

        .services-col--title {
          font-family: 'Playfair Display', serif;
          font-weight: 700;
          line-height: 1.6;
          font-weight: normal;
          -webkit-font-smoothing: antialiased;
          -webkit-text-size-adjust: 100%;
          color: black;
          font-size: rfs(1.3rem);
        }

        .services-col--description {
          font-family: 'PT Sans', serif;
          line-height: 1.6;
          color: #9c9c9c;
          font-weight: normal;
          -webkit-font-smoothing: antialiased;
          -webkit-text-size-adjust: 100%;
          font-size: rfs(1rem);
        }
      }
    }
  }
}
