.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333333;
  display: flex;
  flex-direction: column;
}
.footer .footer-container {
  text-align: center;
}
.footer .footer-container .footer-row {
  padding: 35px;
}
.footer .footer-container .footer-row .footer-col {
  color: white;
  text-align: center;
}
.footer .footer-container .footer-row .footer-col .footer-col-container {
  display: inline-block;
  text-align: left;
}
.footer .footer-container .footer-row .footer-col .footer-col-container .footer-container__heading {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: rfs(1.5rem);
}
.footer .footer-container .footer-row .footer-col .footer-col-container .footer-container__description {
  font-family: 'PT Sans', serif;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  color: #a0a0a0;
  font-size: rfs(0.8rem);
}
.footer .footer-container .footer-row .footer-col .footer-col-container .footer-container__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: rfs(1rem);
}
.footer .footer-container .footer-row .footer-col .footer-col-container .footer-container__list {
  text-decoration: none;
  list-style: none;
  padding-left: 0;
}
.footer .footer-container .footer-row .footer-col .footer-col-container .footer-container__list-item a {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #a0a0a0;
  font-size: rfs(0.8rem);
  cursor: pointer;
}
