@mobile: ~'(max-width: 767px)';

.navbar {
  height: 100%;
  z-index: 10;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 5%;
  margin-right: 5%;
}

.navbar-brand {
  font-size: rfs(1rem);
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  text-align: center;

  .navbar-image {
    width: 25%;
  }
}

.navbar-nav {
  color: black;
  float: right;
  background-color: white;
}

.navbar-collapse {
  padding-top: 5px;
  justify-content: flex-end;
}

.navbar-nav {
  a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: rfs(1rem);
    color: black !important;
    list-style: none;
    margin: 0 1.2em;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 50%;
    text-align: center;
    white-space: nowrap;
  }
}

@media @mobile {
  .navbar-collapse {
    text-align: center;

    .navbar-nav {
      float: none;
      vertical-align: top;
      flex-direction: row;
  
      a {
        float: none;
        margin: 0 auto;
        display: block;
        text-align: center;
      }
    }
  }
}
